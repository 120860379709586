import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import ical from 'ical-generator';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function Schedule() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [shiftString, setShiftString] = useState('');
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [schedule, setSchedule] = useState([]);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [datesWithShifts, setDatesWithShifts] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const generatedSchedule = generateSchedule(shiftString, year, month);
    setSchedule(generatedSchedule);
    setShowDownloadButton(true);
  };

  const generateSchedule = (shiftString, year, month) => {
    const shifts = shiftString.toLowerCase().split('');
    const startDate = new Date(year, month - 1, 1);
    let shiftDays = {};

    const events = shifts.map((shift, index) => {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + index);
      const day = currentDate.getDate();
      const shiftTimes = getShiftTimes(shift);
      const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
      const formattedDate = `${currentDate.getMonth() + 1}/${day}/${year}`; // Keep full year format

      if (shift === 'o') {
        return {
          date: formattedDate,
          title: 'Day Off',
          description: 'No shift today.',
        };
      }

      if (!shiftTimes) return null;

      shiftDays[day] = shiftTimes.label;

      return {
        date: formattedDate,
        title: `Wormy Workday [${shiftTimes.start} - ${shiftTimes.end}]`,
        description: `Work shift from ${shiftTimes.start} to ${shiftTimes.end}`,
        dayOfWeek: dayOfWeek,
        startHour: shiftTimes.startHour,
        endHour: shiftTimes.endHour,
        shiftTimes: `${shiftTimes.start} - ${shiftTimes.end}`
      };
    }).filter(Boolean);

    setDatesWithShifts(shiftDays);
    return events;
  };

  const getShiftTimes = (shift) => {
    switch (shift) {
      case 'm':
        return { label: 'M', start: '6:00 AM', end: '4:00 PM', startHour: 6, endHour: 16 };
      case 'a':
        return { label: 'A', start: '2:00 PM', end: '12:00 AM', startHour: 14, endHour: 24 };
      case 'o':
        return null;
      default:
        return null;
    }
  };

  const downloadICSFile = () => {
    const cal = ical({ name: 'Work Schedule' });

    schedule.forEach((event) => {
      if (event.title !== 'Day Off') {
        const [month, day, year] = event.date.split('/').map(Number);

        cal.createEvent({
          start: new Date(year, month - 1, day, event.startHour),
          end: new Date(year, month - 1, day, event.endHour),
          summary: event.title,
          description: event.description,
        });
      }
    });

    const blob = new Blob([cal.toString()], { type: 'text/calendar' });
    saveAs(blob, 'work_schedule.ics');
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month' && datesWithShifts[date.getDate()]) {
      return <span>{datesWithShifts[date.getDate()]}</span>;
    }
    return null;
  };

  return (
    <div className="container">
      <h1>🐛 🗓️ 🧬</h1>
      <h3>(Wormy Calendar Generator)</h3>
      <br />
      <form onSubmit={handleSubmit}>
        <label className="left-aligned">
          Shift String (e.g., 'aoommmooaaaoomaaooaaoommmooaaa'):
          <br />First letter is the first day of the month.
          <br /> A/a: Afternoon  |  M/m: Morning  |  O/o: Off
          <br />
          <input
            className="input-text"
            type="text"
            value={shiftString}
            onChange={(e) => setShiftString(e.target.value)}
            required
          />
        </label>
        <br />
        <br />
        <label className="left-aligned">
          Select Year:
          <select className="select-menu" value={year} onChange={(e) => setYear(e.target.value)}>
            {[...Array(10).keys()].map(i => {
              const y = currentYear - 5 + i;
              return <option key={y} value={y}>{y}</option>;
            })}
          </select>
        </label>
        <br />
        <label className="left-aligned">
          Select Month:
          <select className="select-menu" value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </label>
        <br />
        <button className="btn" type="submit">Generate Schedule</button>
      </form>

      {showDownloadButton && (
        <>
          <br /><br /><br />
          <h2 className="left-aligned">Generated Schedule:</h2><br />
          <ul className="schedule-list left-aligned">
            {schedule.map((event, index) => (
              <li key={index}>
                {event.title === 'Day Off' 
                  ? `${event.date}  |  OFF` 
                  : `${event.date}  |  ${event.dayOfWeek}  |  ${event.shiftTimes}`}
              </li>
            ))}
          </ul>

          <br /><br />
          <h2 className="left-aligned">Schedule Calendar:</h2><br />
          <Calendar value={new Date(year, month - 1)} tileContent={tileContent} />
          <br />
          <button className="btn" style={{ padding: '12px 24px' }} onClick={downloadICSFile}>
            Download Schedule
          </button><br /><br /><br />
        </>
      )}
    </div>
  );
}

export default Schedule;
