import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Wormptions from './Wormptions'; // Your main page component
import Schedule from './Schedule'; // The schedule component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Wormptions />} /> 
          <Route path="/schedule" element={<Schedule />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
