import React from 'react';
import { Link } from 'react-router-dom';

function Wormptions() {
  return (
    <div className="container">
     <br /><br />
      <h1>🐛 Wormy 🤖</h1>
      <div className="content-box">
        <Link to="/schedule">
          <button className="btn">🐛 Schedule</button>
        </Link>
      </div>
    </div>
  );
}

export default Wormptions;
